import Brands from "@molecules/Brands";
import N5LogoTitle from "@molecules/N5LogoTitle";
import React from "react";
import styles from "./header-and-video.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";

const HeaderAndVideo = () => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.section} text-center ${styles.smokes}`}>
      <N5LogoTitle
        title={t("SERVICES.TITLE")}
        description={t("SERVICES.SUBTITLE")}
      />
      <div className={styles.btnScheduleDemo}>
        <div             
            style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            zIndex: 2,
            textAlign: "center", 
            }}
        >
            <a 
                href="/schedule-demo"
                style={{
                    textDecoration: "none",
                    color: "#fff",
                }}                  
            >
              {t("DEMO.SCHEDULE")}
            </a>
        </div>
      </div>
      <div style={{ marginTop: 100 }}>
        <Brands isServices/>
      </div>
    </section>
  );
};

export default HeaderAndVideo;
